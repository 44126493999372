<template>
  <div class="vjs-component-not-ios-14">
    <div class="vjs-component-not-ios-14__info">
      <h3>Лучше смотреть с компьютера ❤️</h3>
      <p>К сожалению, в новой версии iOS после обновления пока невозможно показывать панорамные видео.</p>
      <p>Apple уже работает над устранением этой проблемы, а мы ждём вас здесь с другого устройства 🙂</p>
      <p>(нажмите, чтобы закрыть сообщение)</p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.vjs-component-not-ios-14 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  font-size: 2em;
  background: black;
}
.vjs-component-not-ios-14__info {
  margin: 0 1em;

  h3 {
    text-align: center;

    p {
      margin-top: 1em;
    }
  }
}
</style>
