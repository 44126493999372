import Centrifuge from 'centrifuge'
import videojs from 'video.js'
const SockJS = require('sockjs-client')

const Plugin = videojs.getPlugin('plugin');

export default class CentrifugePlugin extends Plugin {
  constructor (player, options) {
    super(player, options);
    this.instance = new Centrifuge(options.url, {
      sockjs: SockJS,
    });
  }
}
