import videojs from 'video.js'

const isOrientation = () => window.DeviceOrientationEvent && typeof window.DeviceOrientationEvent.requestPermission === 'function'
const isMotion = () => window.DeviceMotionEvent && typeof window.DeviceMotionEvent.requestPermission === 'function'
const isNeedRequest = () => isMotion() || isOrientation()

var MenuButton = videojs.getComponent('MenuButton')

var requestOrientationButton = videojs.extend(MenuButton, {
  constructor: function (player, options) {
    MenuButton.call(this, player, options)
    this.el().style = 'position:absolute;z-index:1000;width:100%;height:100%'
    this.onClick = options.onClick
  }
})

requestOrientationButton.prototype.handleClick = function (event) {
  this.onClick(event)
}

MenuButton.registerComponent('requestOrientation', requestOrientationButton)

function requestOrientation (options) {

  var settings = videojs.mergeOptions({}, options),
    player = this

  player.ready(function () {

    const button = new requestOrientationButton(player, {
      onClick: () => {
        const next = () => {
          button.el_.style = ''
          options.next()
        }
        if (isOrientation()) {
          DeviceOrientationEvent.requestPermission()
            .then(response => next())
            .catch(err => next())
        }
        if (isMotion()) {
          DeviceMotionEvent.requestPermission()
            .then(response => next())
            .catch(err => next())
        }
        next()
      }
    })
    player.el_.appendChild(button.el_)

  })

}

export default requestOrientation
export {
  isNeedRequest
}

