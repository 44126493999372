<template>
  <div class="vjs-toggle-stream box">
    <input class="type-checkbox" id="vr_mode" v-model="vr_mode" type="checkbox" name="vr_mode">
    <label class="estado" for="vr_mode">
      <span class="toggle-2d">2D</span>
      <span class="toggle-vr">360VR</span>
    </label>
  </div>
</template>

<script>
  export default {
    name: 'App',

    data() {
      return {
        vr_mode: true,
        callback: null,
      }
    },

    methods: {
      registerCallback(callback){
        this.callback = callback;
      },
      setVrMode(vr_mode){
        this.vr_mode = vr_mode;
      }
    },

    watch: {
      async vr_mode(vr_mode) {
        if (this.callback){
          await this.callback(vr_mode);
        }
      }
    }
  }
</script>


<style scoped lang="scss">

  $color1: #005eff;
  $color2: #8000ff;
  .vjs-toggle-stream {
    visibility: hidden;
  }

  .vjs-user-active .vjs-toggle-stream {
    visibility: visible;
  }

  .box {
    position: absolute;
    left: 50%;
    top: 40px;
    transform: translate(-50%, -50%);
  }

  .type-checkbox {
    display: none;
    + label {
      $time: 300ms;
      display: block;
      width: 120px;
      height: 30px;

      background-color: $color1;
      border-radius: 22px;
      box-shadow: 0px 0px 25px -3px rgba(0, 0, 0, 0.4);
      transition: all $time cubic-bezier(0.79, -0.01, 0.36, 1);
      cursor: pointer;
      position: relative;

      span {
        position: absolute;
        color: white;
        font-size: 12px;
      }
      span.toggle-2d {
        top: 50%;
        transform: translateY(-50%);
        left: 2.5em;
        animation: left-check 300ms ease-in-out;
        @keyframes left-check {
          0% {
            opacity: 0;
            left: 5em;
          }
          100% {
            opacity: 1;
            left: 2.5em;
          }
        }
      }

      span.toggle-vr {
        top: 50%;
        transform: translateY(-50%);
        right: 1.3em;
        display: none;
        animation: right-check 300ms ease-in-out;
        @keyframes left-check {
          0% {
            opacity: 0;
            right: 5em;
          }
          100% {
            opacity: 1;
            right: 1.3em;
          }
        }
      }

      &:before {
        display: block;
        content: "";
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: white;
        box-shadow: 0px 0px 25px -3px rgba(0, 0, 0, 0.4);
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        left: 90px;
        transition: left $time cubic-bezier(0.79, -0.01, 0.36, 1);
      }
    }

    &:checked {

      + label {
        background-color: $color2;

        span.toggle-2d {
          display: none;
        }

        span.toggle-vr {
          display: block;
        }

        &:before {
          left: 5px;
        }
      }
    }
  }
</style>
