<template>
  <div class="chat-comment" ref="root" v-if="isOpen">
    <div class="chat-comment__container" ref="comment__container"
         @scroll="onScrollContainer" v-chat-scroll="{always: false, smooth: true}">


      <template v-for="item in messagesSlice">
        <div class="chat-comment__el" v-if="item.user">
          <p class="chat-comment__message" v-text="item.message"></p>
          <div class="chat-comment__bottom">
            <p class="chat-comment__nickname">{{ item.user.name }}</p>
            <p class="chat-comment__time">{{ item.date | shortDate }}</p>
          </div>
        </div>
        <div v-else class="chat-comment__el">
          <div class="chat-comment__logotype">
            <img src="/lookport-v2/img/logo.svg" alt="">
          </div>
          <p class="chat-comment__message">
            {{ item.message }}
          </p>
          <a class="chat-comment__link" href="https://lookport.live/chatinfo">{{ trans.more }}</a>
        </div>
      </template>

    </div>
    <div class="chat-comment__el chat-comment__el--user">
      <template v-for="(like,key) in likes" :key="key">
        <div class="chat-comment__like-animate is-active" v-if="like.icon === 'like0'">
          ❤️
        </div>
        <div class="chat-comment__like-animate-2 is-active" v-if="like.icon === 'like1'">
          🥂
        </div>
        <div class="chat-comment__like-animate-3 is-active" v-if="like.icon === 'like2'">
          👏
        </div>
      </template>
      <template v-if="user">
        <a class="chat-comment__like" href="#" @click.prevent="like('like0')">
          ❤️
        </a>
        <a class="chat-comment__like-2" href="#" @click.prevent="like('like1')">
          🥂
        </a>
        <a class="chat-comment__like-3" href="#" @click.prevent="like('like2')">
          👏
        </a>

        <a class="chat-comment__user" href="#">
          <img :src="user.photo_url">
        </a>
        <div class="chat-comment__input">
          <div class="ui-group">
            <input class="ui-input" type="text" v-model="newMessage" @keyup.enter="sendMessage" name="" placeholder="Отправить сообщение...">
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import 'moment/locale/ru'
import $ from 'jquery/dist/jquery.slim'

export default {
  name: 'VjsChat',

  props: {
    channel: String,
    centrifuge: Object,
  },

  data () {
    return {
      isOpen: false,
      newMessage: '',
      messages: [],
      user: null,
      likes: [],
      trans: {},
      users: [],
    }
  },
  computed: {
    messagesSlice () {
      return this.messages.slice(Math.max(this.messages.length - 50, 0))
    },
  },
  methods: {
    onScrollContainer (e) {
      if (this.$refs.comment__container.clientHeight > this.$refs.root.clientHeight * 0.8) {
        Array.from(e.target.children).forEach(function (el) {
          $(el).css('opacity', $(el).position().top / 400)
        })
      }
    },
    show () {
      this.isOpen = true
    },
    close () {
      this.isOpen = false
    },
    async sendMessage () {
      if (this.newMessage) {
        const message = this.newMessage
        this.newMessage = ''
        await this.centrifuge.publish(this.channel, {
          message: message,
          date: moment.now()
        })
      }
    },
    like (icon) {
      let like = {
        type: 'like',
        user: this.user,
        timestamp: (new Date()).getTime(),
        icon: icon
      }
      this.centrifuge.publish(`viewer-event:${this.channel}`, like)
    },
    listen () {
      this.centrifuge.on('connect', async ({ data: { user } }) => {
        this.user = user
        const { data } = await this.centrifuge.namedRPC('history', {
          channel: this.channel
        })
        this.messages = data
        this.centrifuge.subscribe(this.channel, async ({
          data: { message },
          info
        }) => {
          this.messages.push({
            user: info?.conn_info?.user || null,
            message: message,
          })
        })

        this.centrifuge.subscribe(`viewer-event:${this.channel}`, async ({ data }) => {
          this.pushLike(data)
        })
      })

      this.centrifuge.on('error', (e) => {
        console.error('error', e)
      })

    },
    pushLike (like) {
      this.likes.push(like)

      setTimeout(() => {
        let likes = this.likes.filter((like) => like.timestamp > (new Date()).getTime() - 5000)
        for (let key in likes) {
          this.likes.splice(likes.indexOf(key), 1)
        }
      }, 5000);
    },
    init () {

    }
  },

  async mounted () {
    this.listen()
    this.centrifuge.connect()

    this.$nextTick(() => {
      this.init()
    })
  },
  destroyed () {
    this.centrifuge.disconnect();
  },
  filters: {
    shortDate (date) {
      return moment(date).fromNow()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
