
const LOG_PREFIX = "::VAC::";

// Are we in debug mode?
function debug(){
	return !!window.VAC_DEBUG;
}

// Build arguments for console fn, adding prefix
function buildArgs(args){
	return [LOG_PREFIX].concat(args);
}

// Log message/data
const log = (...args) => {
    if(!debug()) return;
    console.log.apply(null, buildArgs(args));
}

// Error message/data
const error = (...args) => {
    if(!debug()) return;
    console.error.apply(null, buildArgs(args));
}

// Info message/data
const info = (...args) => {
    if(!debug()) return;
    console.info.apply(null, buildArgs(args));
}

export default {
    log,
    error,
    info,
}
