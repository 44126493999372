import Vue from 'vue'
import template from './template.vue'

export default function (options) {
  const vue = new Vue({
    propsData: {
      callback: async (camera) => await options.callback(camera),
      cameras: options.cameras
    },
    ...template
  })
  this.el().appendChild(vue.$mount().$el)
}
