<template>
  <div class="vjs-camera-switcher">
    <ul role="menu" class="vjs-menu-content">
      <li role="menuitemradio" class="vjs-menu-item" v-for="(camera, key) in cameras" :key="key"
          :class="{ 'vjs-camera-selected' : currentCamera !== null && typeof currentCamera === 'object' && camera.name === currentCamera.name }"
          @click="selectCamera(camera)"
      >
        <span class="vjs-menu-item-text">{{ camera.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'App',

  props: {
    cameras: Array,
    callback: Function,
  },

  data () {
    return {
      currentCamera: null,
    }
  },

  methods: {
    async selectCamera (camera) {
      const is_completed = await this.callback(camera)
      if (is_completed || is_completed === undefined) {
        this.currentCamera = camera
      }
    }
  },
  beforeMount () {
    if (this.cameras[0] !== undefined) {
      this.selectCamera(this.cameras[0])
    }
  }
}
</script>


<style scoped lang="scss">
.vjs-menu-content li {
  font-size: 1em;
  text-align: left;
}

.vjs-user-active .vjs-camera-switcher {
  visibility: visible;
}

.vjs-playing .vjs-camera-switcher {
  visibility: visible;
}

.vjs-user-inactive.vjs-paused.vjs-camera-switcher {
  visibility: visible;
}

.vjs-error .vjs-camera-switcher {
  visibility: visible !important;
}

.vjs-camera-switcher {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  visibility: hidden;
  z-index: 5;

  ul {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: solid 1px #ffffff96;
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 10em;

    li {
      cursor: pointer;
      text-transform: uppercase;
      list-style: none;
      margin: 0;
      padding: 1em;
      line-height: 1.4em;
      font-size: 1.2em;
      text-align: center;

      &:first-child {
        border-top-right-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
      }

      &:hover, &.vjs-camera-selected {
        color: black;
        background-color: #ffffff96;
      }
    }
  }
}
</style>
