import videojs from 'video.js'
// if(typeof window.videojs === 'undefined' && typeof require === 'function') {
//   videojs = require('video.js');
// } else {
//   videojs = window.videojs;
// }

// (function(window, videojs) {
var defaults = {
  ui: true
}

/*
 * Resolution menu item
 */

// var Screenfull = videojs.extend(MenuItem, {
//   constructor: function(player, options){

//     options.selectable = true;
//     // Sets this.player_, this.options_ and initializes the component
//     MenuItem.call(this, player, options);
//     this.src = options.src;
//     player.on('resolutionchange', videojs.bind(this, this.update));
//   }
// });
// Screenfull.prototype.update = function(){
//   if(!this.player_)return false;
//   // var selection = this.player_.currentResolution();
//   // this.selected(this.options_.label === selection.label);
//   // this.player_.controlBar.resolutionSwitcher.lastElementChild.textContent = selection.label;
//   // this.player_.controlBar.resolutionSwitcher.children[1].classList.remove('vjs-hidden');
// };
// MenuItem.registerComponent('Screenfull', Screenfull);

var MenuButton = videojs.getComponent('Button')

var ScreenfullButton = videojs.extend(MenuButton, {
  constructor: function (player, options) {
    this.label = document.createElement('span')
    options.label = 'Fullscreen'
    // Sets this.player_, this.options_ and initializes the component
    MenuButton.call(this, player, options)
    this.el().setAttribute('aria-label', 'Fullscreen')
    this.controlText('Fullscreen')
    this.el().className += ' vjs-fullscreen-control '
    this.active_ = false
    this.onResize = options.onResize
    //  this.options = options
  }
})

ScreenfullButton.prototype.handleClick = function (event) {
  if (this.player_.isFullWindow) {
    this.player_.exitFullWindow()
    this.trigger('fullscreenchange')
    if (player_.el_.getElementsByClassName('vjs-close-button')[0]) {
      player_.el_.getElementsByClassName('vjs-close-button')[0].style.display = 'block'
    }
    this.onResize()
  } else {
    this.player_.enterFullWindow()
    this.trigger('fullscreenchange')
    if (player_.el_.getElementsByClassName('vjs-close-button')[0]) {
      player_.el_.getElementsByClassName('vjs-close-button')[0].style.display = 'none'
    }
    this.onResize()
  }
  // this.player_.currentResolution(this.options_.label);
}

MenuButton.registerComponent('ScreenfullButton', ScreenfullButton)

function videoJsResolutionSwitcher (options) {

  const settings = videojs.mergeOptions(defaults, options)
  const player = this

  player.ready(function () {
    if (videojs.browser.IS_IOS && settings.ui) {
      const menuButton = new ScreenfullButton(player, settings)
      player.controlBar.screenful = player.controlBar.appendChild(menuButton.el_)
      // menuButton.handleClick();
      player.controlBar.screenful.dispose = function () {
        this.parentNode.removeChild(this)
      }
    }
  })

}

export default videoJsResolutionSwitcher

