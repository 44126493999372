/**
 * @file touchOverlay.js
 * Touch UI component
 */

import videojs from 'video.js';
import window from 'global/window';

const Component = videojs.getComponent('Component');
const dom = videojs.dom || videojs;

/**
 * The `TouchOverlay` is an overlay to capture tap events.
 *
 * @extends Component
 */
const nextTrackIcon = `
<svg class="track-next-icon"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
<path class="cls-1" d="M139.86,201.05a9.83,9.83,0,0,1,0,17.06L94.53,244.25,49.2,270.4a9.86,9.86,0,0,1-14.8-8.5V157.25a9.83,9.83,0,0,1,14.8-8.49L94.6,174.9Z"/><path class="cls-1" d="M250.26,201.05a9.83,9.83,0,0,1,0,17.06l-45.34,26.14L159.59,270.4a9.85,9.85,0,0,1-14.79-8.5V157.25a9.82,9.82,0,0,1,14.79-8.49L205,174.9Z"/><path class="cls-1" d="M360.65,201.05a9.83,9.83,0,0,1,0,17.06l-45.33,26.14L270,270.4a9.85,9.85,0,0,1-14.79-8.5V157.25A9.82,9.82,0,0,1,270,148.76L315.4,174.9Z"/></svg>
`;
const nextTimeIcon = `
<svg class="rewind-next-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
<path class="cls-1" d="M195.06,201.05a9.83,9.83,0,0,1,0,17.06l-45.33,26.14L104.4,270.4a9.86,9.86,0,0,1-14.8-8.5V157.25a9.83,9.83,0,0,1,14.8-8.49l45.4,26.14Z"/><path class="cls-1" d="M305.45,201.05a9.82,9.82,0,0,1,0,17.06l-45.33,26.14L214.79,270.4A9.85,9.85,0,0,1,200,261.9V157.25a9.82,9.82,0,0,1,14.79-8.49L260.2,174.9Z"/></svg>
`;
class TouchOverlay extends Component {

  /**
  * Creates an instance of the this class.
  *
  * @param  {Player} player
  *         The `Player` that this class should be attached to.
  *
  * @param  {Object} [options]
  *         The key/value store of player options.
  */
  constructor(player, options) {
    super(player, options);
    // console.log()
    try {


    this.seekSeconds = options.seekSeconds;
    this.tapTimeout = options.tapTimeout;
    this.next = options.next;
    this.prev = options.prev;
    this.el = options.el;
    this.canvas = options.el;
    this.playerEl = player.el_;
    const [ rewindSecNext ] = document.getElementsByClassName('rewind-sec-next-preview');
    const [ rewindSecPrev ] = document.getElementsByClassName('rewind-sec-prev-preview');
    this.rewindSecNext = rewindSecNext || document.createElement('div');
    this.rewindSecNext.className = 'rewind-sec-next-preview';
    this.rewindSecPrev = rewindSecPrev || document.createElement('div');
    this.rewindSecPrev.className ='rewind-sec-prev-preview';
    if ( !rewindSecNext ) this.rewindSecNext.innerHTML = `<div>15 сек вперед</div><div>${nextTimeIcon}</div>`;
    if ( !rewindSecPrev ) this.rewindSecPrev.innerHTML = `<div>15 сек назад</div><div>${nextTimeIcon}</div>`;
    this.playerEl.appendChild(this.rewindSecNext);
    this.playerEl.appendChild(this.rewindSecPrev);

    const [ trackNext ] = document.getElementsByClassName('track-next-preview');
    const [ trackPrev ] = document.getElementsByClassName('track-prev-preview');
    this.trackNext = trackNext || document.createElement('div');
    this.trackNext.className = 'track-next-preview';
    this.trackPrev = trackPrev || document.createElement('div');
    this.trackPrev.className ='track-prev-preview';
    if ( !trackNext ) this.trackNext.innerHTML = `
      <div class="track-next-container">
        <div class="track-next-subtitle"> след. трек </div>
        <div class="track-next-title"> Track name </div>
      </div>
      <div class="track-next-icon">${nextTrackIcon}</div>
    `;
    if ( !trackPrev ) this.trackPrev.innerHTML = `
      <div class="track-prev-container">
        <div class="track-prev-subtitle"> пред. трек </div>
        <div class="track-prev-title"> Track name </div>
      </div>
      <div class="track-prev-icon">${nextTrackIcon}</div>
    `;
    this.playerEl.appendChild(this.trackNext);
    this.playerEl.appendChild(this.trackPrev);
    // console.log('?',options)
    // this.el = this.player.vr().domElement;
    // Add play toggle overlay
    // this.addChild()
    this.addChild('playToggle', {});

    // Clear overlay when playback starts or with control fade
    player.on(['playing', 'userinactive'], e => {
      this.removeClass('show-play-toggle');
    });

    // A 0 inactivity timeout won't work here
    if (this.player_.options_.inactivityTimeout === 0) {
      this.player_.options_.inactivityTimeout = 5000;
    }

    this.enable();
    window._lookport_player_log('init');
  } catch (error) {
    window._lookport_player_log('init err',error);
  }
  }

  /**
   * Builds the DOM element.
   *
   * @return {Element}
   *         The DOM element.
   */
  // createEl() {
  //   const el = dom.createEl('div', {
  //     className: 'vjs-touch-overlay',
  //     // Touch overlay is not tabbable.
  //     tabIndex: -1
  //   });

  //   return el;
  // }

  /**
  * Debounces to either handle a delayed single tap, or a double tap
   *
   * @param {Event} event
   *        The touch event
   *
   */
  handleTap(event) {
    // if (event.target !== this.el_) {
    //   return;
    // }
    event.preventDefault();
    window._lookport_player_log("handleTap")

    // event.preventDefault();
    if ( this.secondTapCaptured){
      this.secondTapCaptured = false;
      if (this.timeout) window.clearTimeout(this.timeout);
      this.handleTripleTap(event);

    }else if (this.firstTapCaptured) {
      this.firstTapCaptured = false;
      this.secondTapCaptured = true;
      this.timeout = window.setTimeout(() => {
        this.secondTapCaptured = false;
        this.handleDoubleTap(event);
      }, this.tapTimeout);

    } else {
      this.firstTapCaptured = true;
      this.timeout = window.setTimeout(() => {
        this.firstTapCaptured = false;
        this.handleSingleTap(event);
      }, this.tapTimeout);
    }
  }

  /**
   * Toggles display of play toggle
   *
   * @param {Event} event
   *        The touch event
   *
   */
  handleSingleTap(event) {

    // if (this.player_.paused()) {
    //   this.player_.play();
    // } else {
    //   this.player_.pause();
    // }

  }

  /**
   * Seeks by configured number of seconds if left or right part of video double tapped
   *
   * @param {Event} event
   *        The touch event
   *
   */
  handleDoubleTap(event) {
    window._lookport_player_log('handleDoubleTap')
    event.preventDefault()
    try {


    const rect = this.canvas.getBoundingClientRect();
    const clientX = event.type=='touchstart'? event.touches[0].clientX : event.clientX;
    const x = clientX - rect.left;
    // window._lookport_player_log('clientX',event.touches[0].clientX)
    // Check if double tap is in left or right area
    if (x < rect.width * 0.4) {
      this.player_.currentTime(Math.max(
        0, this.player_.currentTime() - this.seekSeconds));
        this.playerEl.classList.add('rewind-sec-prev');
         this.player_.play();
      this.addClass('reverse');
    } else if (x > rect.width - (rect.width * 0.4)) {
      this.player_.currentTime(Math.min(
        this.player_.duration(), this.player_.currentTime() + this.seekSeconds)
      );
      this.addClass('rewind-sec-next');
       this.player_.play();
      this.playerEl.classList.add('rewind-sec-next');
      this.removeClass('reverse');
    } else {
      return;
    }
  } catch (error) {
      window._lookport_player_log(error)
  }
    // Remove play toggle if showing
    this.removeClass('show-play-toggle');
    setTimeout(()=>{
      this.playerEl.classList.remove('rewind-sec-prev');
      this.playerEl.classList.remove('rewind-sec-next');
    },1000);

    // Remove and readd class to trigger animation
    this.removeClass('skip');
    window.requestAnimationFrame(() => {
      this.addClass('skip');
      // this.removeClass('rewind-sec-next');
      // this.removeClass('rewind-sec-prev');
    });
  }

  handleTripleTap(event) {
    event.preventDefault()
    const rect = this.canvas.getBoundingClientRect();
    const clientX = event.touches? event.touches[0].clientX : event.clientX
    const x = clientX - rect.left;


    if (x < rect.width * 0.4) {
      if (this.prev()){
        this.playerEl.classList.add('track-prev');
        this.player_.play();
      }
    } else if (x > rect.width - (rect.width * 0.4)) {
      if(this.next()){
        this.playerEl.classList.add('track-next');
        this.player_.play();
      }
    } else {
      return;
    }

    setTimeout(()=>{
      this.playerEl.classList.remove('track-prev');
      this.playerEl.classList.remove('track-next');
    },1000);
  }
  enable() {
    this.firstTapCaptured = false;
    if (videojs.browser.IS_ANDROID || videojs.browser.IS_IOS) {
      this.canvas.addEventListener('touchstart',event=> this.handleTap(event))
    }else{
      this.canvas.addEventListener('click',event=>{
        this.handleTap(event);
      })
    }

    window._lookport_player_log('enable')
  }

  /**
   * Disables touch handler
   */
  disable() {
    window._lookport_player_log('disable')
    // this.off('click', this.handleTap);
  }

}


export default TouchOverlay;
