import videojs from 'video.js'
import TouchOverlay from './touchOverlay.js'
import window from 'global/window'
// Default options for the plugin.
const defaults = {
  fullscreen: {
    enterOnRotate: true,
    lockOnRotate: true,
    iOS: false
  },
  touchControls: {
    seekSeconds: 10,
    tapTimeout: 300,
    disableOnEnd: false
  }
}

const screen = window.screen

const angle = () => {
  // iOS
  if (typeof window.orientation === 'number') {
    return window.orientation
  }
  // Android
  if (screen && screen.orientation && screen.orientation.angle) {
    return window.orientation
  }
  videojs.log('angle unknown')
  return 0
}

// Cross-compatibility for Video.js 5 and 6.
const registerPlugin = videojs.registerPlugin || videojs.plugin
const Component = videojs.getComponent('Component')

const onPlayerReady = (player, options) => {

  if (videojs.browser.IS_ANDROID || videojs.browser.IS_IOS) {
    player.addClass('vjs-mobile-ui')

    if (options.fullscreen.iOS &&
      videojs.browser.IS_IOS && videojs.browser.IOS_VERSION > 9 &&
      !player.el_.ownerDocument.querySelector('.bc-iframe')) {
      player.tech_.el_.setAttribute('playsinline', 'playsinline')
      player.tech_.supportsFullScreen = function () {
        return false
      }
    }

    const rotationHandler = () => {
      const currentAngle = angle()

      if (currentAngle === 90 || currentAngle === 270 || currentAngle === -90) {
        if (player.paused() === false) {
          player.requestFullscreen()
          screen.lockOrientationUniversal('landscape')
        }
      }
      if (currentAngle === 0 || currentAngle === 180) {
        if (player.isFullscreen()) {
          player.exitFullscreen()
        }
      }
    }

    if (videojs.browser.IS_IOS) {
      window.addEventListener('orientationchange', rotationHandler)
    } else if (screen && screen.orientation) {
      // addEventListener('orientationchange') is not a user interaction on Android
      screen.orientation.onchange = rotationHandler
    }

    player.on('fullscreenchange', e => {
      console.log('fullscreenchange:')
      console.dir({
        angle: angle(),
        isFullscreen: player.isFullscreen(),
      })
      if (!angle() && player.isFullscreen() && options.fullscreen.alwaysInLandscapeMode) {
        screen.lockOrientationUniversal('landscape')
      }

      if (angle() && !player.isFullscreen()) {
        screen.lockOrientationUniversal('portrait')
      }
    })
  }

  const controlBarIdx = player.children_.indexOf(player.getChild('ControlBar')) - 1

  player.addChild('touchOverlay', {
    ...options.touchControls,
    el: options.el
  }, controlBarIdx)

}

function mobileUi (options) {
  Component.registerComponent('TouchOverlay', TouchOverlay)
  this.ready(() => {
    onPlayerReady(this, videojs.mergeOptions(defaults, options))
  })

}

export default mobileUi
