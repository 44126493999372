	var defaults = {
		image: 'https://lookport.live/front/img/logo.svg',
		destination: 'https://lookport.live/',
    autoHide: false, // default is true
    // width: 'auto', // default is 'auto'
    // height: 'auto', // default is 'auto'
    opacity: 1, // default is 1.0
    position: 'absolute', // default is 'static'
    top: '15px',
    right: '20px',
	};
	// plugin initializer
	function logobrand(options) {
		var player = this;
		var settings = { ...defaults, ...options }
		// link element
		var link = document.createElement("a");
		link.id = "vjs-logobrand-image-destination";
		link.href = settings.destination;
		link.target = "_blank";


		// image element
		var image = document.createElement('div');
		image.id = settings.autoHide?
			'vjs-logobrand-image-autoHide':
			'vjs-logobrand-image';
		// image.src = settings.image;
		// image style config
		image.style.height = settings.height;
		image.style.width = settings.width;
		image.style.opacity = settings.opacity;
		image.style.position = settings.position;
		image.style.top = settings.top;
		image.style.bottom = settings.bottom;
		image.style.left = settings.left;
		image.style.right = settings.right;
		image.innerHTML = template
		link.appendChild(image);
		player.el().appendChild(link);

		return this;
  };

	const template = `
	<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 317 117" style="enable-background:new 0 0 317 117;" xml:space="preserve">
<style type="text/css">
 .st0{fill:#1A1A1A;}
 .st1{fill:url(#SVGID_1_);}
 .st2{fill:#FFFFFF;}

</style>
<g>
 <g>
	 <path class="st5" d="M134.1,40.4h7.8V73h-7.8V40.4z"/>
	 <path class="st5" d="M144.2,61.4c0-7.5,5.5-12.2,12.9-12.2S170,53.9,170,61.4s-5.5,12.2-12.9,12.2S144.2,68.8,144.2,61.4z
			M152.2,61.4c0,3,2,4.9,4.9,4.9c3,0,4.9-1.8,4.9-4.9c0-3-2-4.9-4.9-4.9C154.1,56.5,152.2,58.3,152.2,61.4z"/>
	 <path class="st5" d="M171,61.4c0-7.5,5.5-12.2,12.9-12.2c7.4,0,12.9,4.8,12.9,12.2s-5.5,12.2-12.9,12.2
		 C176.5,73.6,171,68.8,171,61.4z M178.9,61.4c0,3,2,4.9,4.9,4.9c3,0,4.9-1.8,4.9-4.9c0-3-2-4.9-4.9-4.9
		 C180.9,56.5,178.9,58.3,178.9,61.4z"/>
	 <path class="st5" d="M208.6,64.3h-1.7V73h-7.8V40.4h7.8v18.1h1.6l6.7-8.7h7.8v1.1l-7.7,9.8v1l8.3,10.4V73h-8.3L208.6,64.3z"/>
	 <path class="st5" d="M224.2,49.7h7.8v2.2c1.8-1.8,4.2-2.8,7-2.8c6,0,10.4,4.8,10.4,12.2S245,73.6,239,73.6c-2.8,0-5.2-1-7-2.8v9.4
		 h-7.8V49.7z M231.7,61.4c0,3,1.9,4.9,4.9,4.9s4.9-1.8,4.9-4.9c0-3-1.9-4.9-4.9-4.9S231.7,58.3,231.7,61.4z"/>
	 <path class="st5" d="M250.4,61.4c0-7.5,5.5-12.2,12.9-12.2c7.4,0,12.9,4.8,12.9,12.2s-5.5,12.2-12.9,12.2
		 C255.8,73.6,250.4,68.8,250.4,61.4z M258.3,61.4c0,3,2,4.9,4.9,4.9c3,0,4.9-1.8,4.9-4.9c0-3-2-4.9-4.9-4.9
		 C260.3,56.5,258.3,58.3,258.3,61.4z"/>
	 <path class="st5" d="M278.5,49.7h7.8v2.4c1.7-1.9,4.2-2.4,6.9-2.4V57c-0.6,0-1.6,0-2.4,0c-2.1,0-4.1,1.1-4.6,3.2V73h-7.8V49.7z"/>
	 <path class="st5" d="M303.5,49.7h4.8V57h-4.8v5.6c0,2.2,1,3.5,3.1,3.5c0.2,0,1.2-0.1,1.4-0.1v7.3c-0.9,0.2-2.8,0.4-3.8,0.4
		 c-6.3,0-8.5-4.8-8.5-10.9V57h-2.8v-7.3h2.8V45l7.8-2.3V49.7z"/>
 </g>
 <g>
	 <path class="st2" d="M61.3,115.1c-31.3,0-56.7-25.4-56.7-56.7S30.1,1.6,61.3,1.6s56.7,25.4,56.7,56.7S92.6,115.1,61.3,115.1z
			M61.3,16C38,16,19,35,19,58.3s19,42.4,42.4,42.4s42.4-19,42.4-42.4S84.7,16,61.3,16z"/>
 </g>
 <g>
	 <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="43.0191" y1="58.341" x2="79.2277" y2="58.341">
		 <stop  offset="0" style="stop-color:#8000FF"/>
		 <stop  offset="1" style="stop-color:#005EFF"/>
	 </linearGradient>
	 <path class="st1" d="M61.3,86.7C45.7,86.7,33,74,33,58.3C33,42.7,45.7,30,61.3,30s28.4,12.7,28.4,28.4C89.7,74,77,86.7,61.3,86.7z
			M61.3,44.3c-7.7,0-14,6.3-14,14c0,7.7,6.3,14,14,14s14-6.3,14-14C75.3,50.6,69.1,44.3,61.3,44.3z"/>
 </g>
</g>
</svg>

`;
  export default logobrand
	// register the plugin with video.js
