import Vue from 'vue';
import App from "./App.vue";
import videojs from 'video.js';

const vue = new Vue({
  ...App
});



const defaultsOptions = {
  ui: true,
  onChangeVrMode: () => {},
  vr_mode: true,
}

function ToggleStream(options) {
  let settings = videojs.mergeOptions(defaultsOptions, options);
  this.el().appendChild(vue.$mount().$el);
  vue.setVrMode(settings.vr_mode);
  vue.registerCallback(settings.onChangeVrMode);
}

export default ToggleStream;
