import * as THREE from "three"

// import OrbitControls from 'three-orbitcontrols';


function Compass (vr) {
  if (!this.compassEl){
    const container = document.createElement('div');
    container.style = `
      position: absolute;
      width:65px;
      height:65px;
      top: calc(50% - 32.5px);
      right:15px;
      opacity:0.7;
    `;

    container.innerHTML = template;
    this.el().appendChild(container);
    container.onclick=()=>{
      const angle =  vr.controls3d.orbit.getPolarAngle()
      const init = 1.5013870711947899;
      vr.controls3d.orbit.rotateLeft(vr.controls3d.orbit.getAzimuthalAngle()*0.2)
      vr.controls3d.orbit.rotateUp((init-angle)*-0.2)
    }
  }
  const compass = this.compassEl || document.getElementById('compass');
  this.compassEl = compass


  const { scene, camera, renderer, controls3d } = vr;
  // this.vr().on('initialized',()=>{console.log('init')})
  var dir = new THREE.Vector3();
  var sph = new THREE.Spherical();

  renderer.setAnimationLoop(() => {
    // if(!this.init && vr.controls3d){
    //   this.init=true
    //   vr.controls3d.orbit.rotateUp(-0.3)
    // }
    camera.getWorldDirection(dir);
    sph.setFromVector3(dir);
    compass.style.transform = `rotate(${180 - THREE.MathUtils.radToDeg(sph.theta)}deg)`;
  });

}

const template = `
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 445" width="100%" height="100%">
    <defs><style>
      .cls-1{fill:#fff;}
    </style></defs>
    <path class="cls-1" d="M214.5,90.68l-6.86-11.59-8-14-8,14-6.86,11.59C118.92,98,67.58,154.09,67.58,222a132,132,0,1,0,264,0C331.62,154.09,280.28,98,214.5,90.68ZM199.6,340.06c-65.07,0-118.06-53-118.06-118.07s53-118.3,118.06-118.3,118.06,53,118.06,118.07S264.66,340.06,199.6,340.06Z"/>
    <circle class="radar" cx="199.6" cy="221.99" r="16.09"/>
    </svg>
    <svg id="compass" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 445" width="100%" height="100%" style="position: absolute;top:0;left:0;">
    <defs><style>.radar{fill:#fff;}</style>
    </defs><title>compass</title>
    <path class="radar" d="M199.6,196.2a25.56,25.56,0,0,1,18.22,7.58l58-58a107.66,107.66,0,0,0-152.37,0l58,58A25.54,25.54,0,0,1,199.6,196.2Z"/>
  </svg>
`;


export default Compass;
