import Vue from 'vue'
import template from './template.vue'
import videojs from 'video.js'

export default function (options) {
  const vue = new Vue({
    ...template
  });

  const container = vue.$mount().$el;
  container.addEventListener('click',() => {
    this.el().removeChild(container);
  })

  if (videojs.browser.IS_IOS && parseInt(videojs.browser.IOS_VERSION) === 14) {
    this.player().on('change_projection', (projection) => {
      this.el().removeChild(container);
      if (this.currentResolution() && this.currentResolution().sources) {
        const source = this.currentResolution().sources.find(s => s.type === 'application/x-mpegURL')
        if (source && projection !== 'NONE') {
          this.el().appendChild(container)
        }
      }
    })
  }
}
