

import Logger  from "./logger";


const EventRegistry = {
    AnnotationState: {
        openAnnotation: (event, _this) => {
            _this.openAnnotationById(event.detail.id);
        },
        closeActiveAnnotation: (event, _this) => {
            _this.clearActive();
        },
        newAnnotation: (event, _this) => {
            _this.createAndAddAnnotation(event.detail);
        },
        destroyAnnotation: (event, _this) => {
            _this.destroyAnnotationById(event.detail.id);
        },
        newComment: (event, _this) => {
            let annotation = _this.findAnnotation(event.detail.annotationId);
            if(annotation) annotation.commentList.createComment(event.detail.body);
        },
        destroyComment: (event, _this) => {
            let comment = _this.findComment(event.detail.id);
            if(comment) comment.commentList.destroyComment(event);
        }
    },
    Controls: {
        addingAnnotation: (event, _this) => {
            _this.startAddNew();
        },
        cancelAddingAnnotation: (event, _this) => {
            _this.cancelAddNew();
        }
    },
    PlayerButton: {
        onStateChanged: (event, _this) => {
            _this.updateNumAnnotations();
        }
    },
    AnnotationComments: {
        toggleAnnotationMode: (event, _this) => {
            _this.toggleAnnotationMode();
        }
    }
};

export default class EventDispatcher {

    constructor (plugin) {
        this.plugin = plugin;
        this.pluginReady = false;
        this.pendingEvts = [];
        this.registeredListeners = [];
        this.eventRegistry = EventRegistry;
    }

    // Use the EventRegistry to mass register events on each component initialization
    registerListenersFor (obj, className) {
        let matchingEvents = this.eventRegistry[className];
        if (matchingEvents) {
            Object.keys(matchingEvents).forEach((key) => {
                // Don't register again if already in cached collection
                if (!~this.registeredListeners.indexOf(key)) {
                    let callback = matchingEvents[key].bind(obj);
                    this.registerListener(key, ((evt) => {
                        if(!this.pluginReady) return;
                        this.logCallback(key, className, evt);
                        callback(evt, obj);
                    }).bind(this));
                }
            });
        }
    }

    // Bind a listener to the plugin
    registerListener (type, callback) {
        this.plugin.on(type, callback);
        this.registeredListeners.push(type);
    }

    // Unbind a listener from the plugin
    unregisterListener (type) {
        this.plugin.off(type);
        let i = this.registeredListeners.indexOf(type);
        this.registeredListeners.splice(i, 1);
    }

    // Trigger an event on the plugin
    fire (type, data) {
        if(!this.pluginReady) return;
        Logger.log("evt-dispatch-FIRE", type, data);
        let evt = new CustomEvent(type, { 'detail': data });
        evt = {...evt}
        console.log(evt)
        this.plugin.trigger(evt);
    }

    teardown () {
        this.registeredListeners.forEach((type) => { this.unregisterListener(type) });
    }

    logCallback (eventName, className, event) {
        Logger.log("evt-dispatch-RECEIVE", `${eventName} (${className})`, event);
    }
}
