import Vue from 'vue'
import template from './template.vue'

export default function (options) {
  const vue = new Vue({
    propsData: {
      callback: options.callback,
      end_time: options.end_time
    },
    ...template
  })
  this.el().appendChild(vue.$mount().$el)
}
