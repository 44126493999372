import Vue from 'vue'
import template from './template.vue'
import videojs from 'video.js'
import VueChatScroll from 'vue-chat-scroll'
Vue.use(VueChatScroll);

videojs.addLanguage('en',{

})



export default function (options) {
  const player = this;

  const centrifuge = player.centrifuge().instance;

  const vueComponent = new Vue({
    propsData: {
      centrifuge: centrifuge,
      channel: options.channel,
    },
    ...template
  });

  class ChatToggleButton extends videojs.getComponent('Button') {
    constructor (player, options = {}) {
      super(player, options)
      this.addClass('vjs-chat-button')
    }
    handleClick(_e){
      if (vueComponent.isOpen){
        vueComponent.close();
      }else{
        vueComponent.show();
      }
    }
  }

  const controlBar = player.getChild('ControlBar');
  const chatToggleButton = new ChatToggleButton(player,options);

  this.ready(() => {
    controlBar.addChild(chatToggleButton);
    this.el().appendChild(vueComponent.$mount().$el);

    if (options.hasOwnProperty('open') && options.open) {
      vueComponent.show();
    }
  })
}
