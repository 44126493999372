var defaults = {};

// plugin initializer
function closeButton(options) {
  var player = this;
  var settings = {...{defaults}, ...options}
  var button = document.createElement("button");
  button.className = 'vjs-custom video-js vjs-control vjs-close-button';
  button.style = `
    left: auto;
    right: 20px;
    top: 20px;
    font-size: 3em;
    position: absolute !important;
    width: 1em !important;
    height: 1em !important;
    outline: none !important;
    text-shadow: none !important
  `;
  button.style.display="none";
  button.onclick = function (event) {
    event.preventDefault();
    options.onClose()
  };




  var span = document.createElement('span');
  span.className = 'vjs-icon-placeholder';
  button.appendChild(span)
  player.el().appendChild(button);


  var div = document.createElement('div');
  div.className = 'vjs-anime-placeholder';
  button.appendChild(div)
  player.el().appendChild(button);



  return this;
};


export default closeButton
// register the plugin with video.js
