<template>
  <div class="vjs-timer-container" v-show="show" ref="root">
    <div class="vjs-timer" :style="`font-size:${fontSize}`">
        <span>{{ days | twoDigits }}</span>:<span>{{ hours | twoDigits }}</span>:<span>{{ minutes | twoDigits }}</span>:<span>{{ seconds | twoDigits }}</span>
    </div>
  </div>
</template>

<script>
let interval = null
import axios from 'axios'

export default {
  name: 'flipCountdown',
  props: {
    callback: Function,
    end_time: {
      type: String
    },
  },
  data () {
    return {
      rootWidth: null,
      now: 0,
      date: null,
      interval: null,
      diff: 0,
      show: false,
      start_time: '',
    }
  },
  created () {
    if (!this.end_time) {
      throw new Error('Missing props \'end_time\'')
    }
    const endTime = this.end_time
    this.date = parseInt(endTime);
    if (!this.date) {
      throw new Error('Invalid props value, correct the \'end_time\'')
    }
    this.show = true
    this.interval = setInterval(() => {
      this.now = Math.floor((new Date()).getTime() / 1000);
    }, 1000)
  },

  computed: {
    fontSize() {
      if (this.rootWidth === null){
        return '5vw';
      }
      return `${this.rootWidth / 7}px`;
    },
    show () {
      if (this.diff !== 0) {
        this.show = true
      }
    },
    seconds () {
      return Math.trunc(this.diff) % 60
    },
    minutes () {
      return Math.trunc(this.diff / 60) % 60
    },
    hours () {
      return Math.trunc(this.diff / 60 / 60) % 24
    },
    days () {
      return Math.trunc(this.diff / 60 / 60 / 24)
    }
  },
  watch: {
    end_time: function (newVal, oldVal) {
      const endTime = this.end_time
      this.date = parseInt(endTime);
      if (!this.date) {
        throw new Error('Invalid props value, correct the \'end_time\'')
      }
    },
    now (value) {
      this.diff = this.date - this.now
      if (this.diff <= 0 && this.show) {
        clearInterval(this.interval)
        this.diff = 0
        this.show = false
        this.callback()
      } else {
        this.show = true
      }
    },
  },
  filters: {
    twoDigits (value) {
      if (value.toString().length <= 1) {
        return '0' + value.toString()
      }
      return value.toString()
    }
  },
  methods: {
    resizeHandler(){
      this.rootWidth = this.$refs.root.clientWidth;
    }
  },
  async mounted() {
    this.now = Math.floor((new Date()).getTime() / 1000);
    this.$nextTick(this.resizeHandler);
    window.addEventListener("resize", this.resizeHandler);
  },
  beforeDestroy () {
    if (window['cancelAnimationFrame']) {
      cancelAnimationFrame(this.frame)
    }
  },
  destroyed () {
    clearInterval(interval)
    window.removeEventListener("resize", this.resizeHandler);
  }
}
</script>

<style lang="scss" scoped>
.vjs-timer-container {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: black;
  border-radius: 15px;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;

  .vjs-timer {


    font-size: 8em;
    font-weight: 600;
    line-height: 1.1;
    font-family: 'AGLettericaUltraCompressed', sans-serif;
    color: #161616;
    letter-spacing: 5px;
    text-transform: uppercase;
    padding: 20px;
    font-style: normal;
    background: linear-gradient(90deg, #8000FF 17.72%, #005EFF 81.53%);
    -webkit-text-stroke: 4px rgba(0,0,0,0.01);
    -webkit-background-clip: text;

    .d {
      font-size: 60px;
    }
  }
}
</style>
