const MP4 = (data) => {
  let resolutions = [
    // {src: mp4_4k, label: '4K'},
    // {src: mp4_2k, label: '2K'},
    // {src: mp4_fullhd, label: 'FullHD', res: 1080},
    // {src: mp4_hd, label: 'HD', res: 720},
    // { src: mp4_480, label: 'low' },
  ];

  if (typeof data['full_hd'] === 'string' && data['full_hd'].startsWith('http')) {
    resolutions.push({src: data['full_hd'], label: 'FullHD', res: 1200})
  }
  if (typeof data['2k'] === 'string' && data['2k'].startsWith('http')) {
    resolutions.push({src: data['2k'], label: '2K'})
  }
  if (typeof data['4k'] === 'string' && data['4k'].startsWith('http')) {
    resolutions.push({src: data['4k'], label: '4K'})
  }

  resolutions = resolutions.filter(resolution => resolution.src)
    .map(resolution => {
      resolution.type = 'video/mp4'
      return resolution
    })

  return resolutions
}


const ServiceTrack = (id) => fetch(`https://lookport.live/api/tracks/${id}`, {
  // credentials: "include",
}).then(async (Response) => {
  const data = await Response.json()
  return {
    data: {
      poster: data.photo && data.photo.url ? data.photo.url : '',
      name: data.name,
      broadcast_id: data.broadcast_id,
      slug: data.slug,
      desc: data.desc,
      MP4: MP4(data),
      MP4_2D: [],

      HLS: [],
      HLS_2D: [],
    }
  }
});

export default ServiceTrack
