import "../styles/index.scss";
import "videojs-youtube/dist/Youtube";
import Videojs from "video.js";
require('videojs-vr');


// import Streaming from '@videojs/http-streaming';
import logotype from "./logotype";
import closeButton from "./closeButton";
import resolution from "./resolution";
import compass from "./compass";
import serviceTrack from "./serviceTrack";
import mobile from "./mobile";
import screenfull from "./screenfull";
import requestOrientation, { isNeedRequest } from "./requestOrientation";
import AnnotationComments from "./annotations";
import chat from "./vue-plugins/chat";
import toggleStream from "./toggle_stream";
// import Omnitone from 'omnitone/build/omnitone.min.esm.js';
import $ from "jquery";
import "./log";
import EventEmitter from "events";
import xr from "./vr/plugin";
import cameraSwitcher from "./vue-plugins/cameraSwitcher";
import countdown from "./vue-plugins/countdown";
import notIOS14 from "./vue-plugins/notIOS14";
import centrifugePlugin from "./plugins/centrifuge";
import "videojs-youtube";

const events = new EventEmitter();
Videojs.addLanguage("ru", require("./lang/ru"));
Videojs.registerPlugin("logotype", logotype);
Videojs.registerPlugin("closeButton", closeButton);
Videojs.registerPlugin("resolution", resolution);
Videojs.registerPlugin("screenfull", screenfull);
Videojs.registerPlugin("requestOrientation", requestOrientation);
Videojs.registerPlugin("compass", compass);
Videojs.registerPlugin("annotationComments", AnnotationComments(Videojs));
Videojs.registerPlugin("mobile", mobile);
Videojs.registerPlugin("chat", chat);
Videojs.registerPlugin("xr", xr);
Videojs.registerPlugin("toggleStream", toggleStream);
Videojs.registerPlugin("countdown", countdown);
Videojs.registerPlugin("cameraSwitcher", cameraSwitcher);
Videojs.registerPlugin("notIOS14", notIOS14);
Videojs.registerPlugin("centrifuge", centrifugePlugin);
const Lookport = (selector) => {
  Lookport.dispose = async () => {
    if (Lookport.xr) {
      await Lookport.xr.dispose();
      Lookport.xr = null;
    }
    await Lookport.Player.dispose();
    Lookport.Player = null;
    if (Lookport.container !== undefined) {
      Lookport.container.empty();
    }
  };

  Lookport.mount = async () => {
    const container = $(selector);
    Lookport.container = container;
    const video = document.createElement("video");
    video.oncontextmenu = () => {
      return false;
    };
    // video.height = 300;
    video.className = "video-js vjs-default-skin";
    video.setAttribute("controls", "");
    video.setAttribute("disablePictureInPicture", "");
    // video.setAttribute('playsinline', '')
    video.setAttribute("controlsList", "nodownload");
    video.setAttribute("crossOrigin", "anonymous");
    video.setAttribute("height", "auto");
    video.setAttribute("width", "auto");
    container.empty();
    container.append(video);
    window._lookport_player_log(video.getAttribute("crossorigin"));
    const options = {
      id: "vjs_video",
      debug: true,
      textTrackSettings: false,
      language: "ru",
      controlBar: {
        volumePanel: { inline: false },
        fullscreenToggle: true,
        toggleStream: null,
        chatToggle: null,
      },
      techOrder: ["html5", "youtube"],

      html5: {
        hls: {
          withCredentials: false,
          overrideNative: !Videojs.browser.IS_IOS,
        },
        vhs: {
          withCredentials: false,
          overrideNative: !Videojs.browser.IS_IOS,
          playlists: {
            master: [],
            media: [],
          },
        },
      },
      nativeAudioTracks: !Videojs.browser.IS_IOS,
      nativeVideoTracks: !Videojs.browser.IS_IOS,
      hls: {
        withCredentials: false,
        overrideNative: !Videojs.browser.IS_IOS,
      },
      plugins: {
        // rtmsCore: {
        //   sockjsUrl: "wss://rtms.lookport.live/connection/websocket",
        //   appUrl: "https://lookport.live",
        //   rootChannel: "player",
        // },
        // centrifuge: {
        //   url: "wss://rtms.lookport.live/connection/websocket",
        // },
      },
    };

    Lookport.Player = Videojs(video, options);
    Lookport.Player.mediainfo = Lookport.Player.mediainfo || {};
    Lookport.Player.scaleZoom = 1.0;
    Lookport.Player.setScaleZoom = function (value) {
      Lookport.Player.scaleZoom = value;
    };

    Lookport.Player.logotype({
      destination: "https://lookport.live/",
      autoHide: false, // default is true
      width: "120px", // default is 'auto'
      // height: '50px', // default is 'auto'
      opacity: 1, // default is 1.0
      position: "absolute", // default is 'static'
      top: "20px",
      left: "20px",
    });
    Lookport.Player.closeButton({
      onClose: async () => {
        if (Lookport.Player.isFullWindow) {
          Lookport.Player.exitFullWindow();
        } else if (Lookport.Player.isFullscreen()) {
          Lookport.Player.exitFullscreen();
        }
        await Lookport.loadSrc(Lookport.sources, Lookport.poster);
      },
    });

    Lookport.Player.resolution();
    Lookport.Player.notIOS14();



    // Lookport.Player.screenfull({
    //   onResize: () => {
    //     if (Lookport.xr) {
    //       Lookport.xr.handleResize_()
    //     }
    //   }
    // })

    events.emit("mount.after");
  };

  Lookport.mount();

  Lookport.loadSrc = async (src, posterUrl = false) => {
    Lookport.sources = src;

    Lookport.poster = posterUrl;
    Lookport.Player.updateSrc(src);
    if (Lookport.annotation) {
      Lookport.annotation.dispose();
      Lookport.annotation = null;
    }

    Lookport.Player.pause();
    Lookport.Player.currentTime(0);
    if (src.length > 0) {
      Lookport.Player.currentResolution(src[0].label);
    }

    if (posterUrl) {
      Lookport.Player.poster(posterUrl);
    }
    const { data } = Lookport;
    if (data !== undefined && data.tracks !== undefined && data.tracks.length) {
      Lookport.annotationIntit();
    }

    if (isNeedRequest()) {
      // iOs 13+
      Lookport.Player.requestOrientation({
        next: () => {
          Lookport.initializeVr();
          Lookport.Player.play();
        },
      });
    }

    // if (!Lookport.xr) {
    //   if (isNeedRequest()) { // iOs 13+
    //     Lookport.Player.requestOrientation({
    //       next: () => {
    //         if (!Lookport.xr) {
    //           Lookport.initialize()
    //         }
    //         Lookport.Player.play()
    //       }
    //     })
    //   } else {
    //     Lookport.Player.on('play', () => {
    //       if (!Lookport.xr) {
    //         const supportsVR = 'getVRDisplays' in navigator
    //         if (supportsVR) {
    //           navigator.getVRDisplays().then(function (displays) {
    //             Lookport.Player.currentResolution('4K')
    //             Lookport.Player.el_.className += ' player-VRDISPLAYS'
    //             Lookport.initialize()
    //           }).catch(() => {
    //             Lookport.initialize()
    //           })
    //         } else {
    //           Lookport.initialize()
    //         }
    //       }
    //     })
    //   }
    // }
  };

  Lookport.showCountdown = async (data) => {
    await Lookport.Player.countdown({
      end_time: data.date_timestamp,
      callback: async () => await Lookport.initialize(data),
    });
  };
  Lookport.enableChat = async (data) => {
    await Lookport.Player.centrifuge({
      url: `wss://${data.rtms_host}/connection/websocket`,
    });
    await Lookport.Player.chat({
      channel: `chat:${data.slug}`,
      open: data.chat_is_open || false
    });
  };
  Lookport.loadCamerasSrc = async (cameras, poster) => {
    Lookport.Player.cameraSwitcher({
      cameras: cameras,
      callback: async (camera) => {
        if (camera.sources.length > 0) {
          const currentTime = Lookport.Player.currentTime();
          await Lookport.loadSrc(camera.sources, poster);
          Lookport.xr.init(camera.projection);
          Lookport.Player.currentTime(currentTime);
          if (Lookport.Player.paused()) {
            Lookport.Player.play();
          }
          return true;
        }
        return false;
      },
    });
  };

  Lookport.load = async (id) => {
    if (!id) throw Error("load id not found");


    const response = await fetch(
      `/api/broadcast-player/${id}`,
      {
        // credentials: "include",
      }
    );
    const data = await response.json();
    return await Lookport.initialize(data);
  };

  Lookport.loadTrack = async (id) => {
    // if(Lookport.Player)Lookport.Player.dispose();
    if (!id) throw Error("load id not found");
    const { data } = await serviceTrack(id);
    Lookport.data = data;
    await Lookport.initializeVr();
    await Lookport.xr.init("NONE");
    await Lookport.loadSrc(data.live ? data.HLS : data.MP4, data.poster);
  };

  Lookport.annotationIntit = () => {
    const { data } = Lookport;
    const annotationsObjects = data.tracks.map((item) => ({
      id: item.id,
      range: {
        start: item.start,
        // end: 15
      },
      comments: [
        {
          id: item.id,
          body: item.name,
          meta: {
            // datetime: '2017-03-28T19:17:32.238Z',
            // user_id: 1,
            // user_name: 'Jack Pope'
          },
        },
      ],
    }));

    // console.log(annotationsObjects)
    if (Lookport.annotation) {
      Lookport.annotation.dispose();
    }
    if (annotationsObjects.length) {
      Lookport.annotation = Lookport.Player.annotationComments({
        annotationsObjects: annotationsObjects,
        //  meta: { user_id: null, user_name: null },
        //  bindArrowKeys: true,
        showControls: false,
        showCommentList: false,
        //  showFullScreen: true,
        showMarkerShapeAndTooltips: true,
        internalCommenting: false,
        startInAnnotationMode: true,
      });
      console.log("annotationsObjects", annotationsObjects.length);
    } else {
      Lookport.annotation = null;
    }
  };

  Lookport.initializeVr = async () => {
    const xr = Lookport.Player.xr({
      debug: true,
      projection: "NONE",
    });
    Lookport.xr = xr;
    Lookport.xr.on("initialized", () => {
      if (!Lookport.initialized !== xr.renderedCanvas) {
        window.addEventListener("orientationchange", () => {
          setTimeout(xr.handleResize_, 500);
        });

        Lookport.initialized = xr.renderedCanvas;
        Lookport.Player.compass(Lookport.xr);
        Lookport.Player.mobile({
          el: xr.renderedCanvas,
          fullscreen: {
            enterOnRotate: true,
            alwaysInLandscapeMode: false,
            iOS: true,
          },
          touchControls: {
            seekSeconds: 15,
            next: () =>
              Lookport.annotation &&
              Lookport.annotation.annotationState.nextAnnotation(),
            prev: () =>
              Lookport.annotation &&
              Lookport.annotation.annotationState.prevAnnotation(),
            tapTimeout: 300,
            disableOnEnd: false,
          },
        });
      }
    });
  };

  Lookport.initialize = async (data = null) => {
    Lookport._data = data;
    if (data === null) {
      data = Lookport._data;
    }
    if (
      Math.floor(new Date().getTime() / 1000) >= data.date_timestamp ||
      data.show_player
    ) {
      if (
        data.chat_is_enabled &&
        window.location.host.indexOf("lookport") !== -1
      ) {
        Lookport.enableChat(data);
      }
      if (window.location.host.indexOf("lookport") !== -1) {
        if (Videojs.browser.IS_ANDROID) {
          Lookport.Player.requestFullscreen();
        } else if (Videojs.browser.IS_IOS) {
          Lookport.Player.enterFullWindow();
        }
      }

      Lookport.initializeVr();

      if (data.sources.length === 1) {
        await Lookport.loadSrc(data.sources[0].sources, data.poster);
        await Lookport.xr.init(data.sources[0].projection);
      } else if (data.sources.length > 1) {
        await Lookport.loadCamerasSrc(data.sources, data.poster);
      }
    } else {
      await Lookport.showCountdown(data);
    }
  };

  return Lookport;
};

Lookport.Events = events;

window.Lookport = Lookport;
export default Lookport;
